<template>
  <div>
    <v-card class="rounded-lg shadow my-5" :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        <div class="f-15">
          Statistiques détaillées
        </div>
        <v-spacer></v-spacer>
        <v-icon color="gifty" @click="getEPaymentsStatistics" class="mr-2">mdi-reload</v-icon>
        <v-btn icon color="gifty" @click="show = !show">
          <v-icon v-if="!show">mdi-plus-circle</v-icon>
          <v-icon v-else>mdi-minus-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider v-if="show"></v-divider>

      <v-card-text v-if="show">
        <v-row>
          <v-col cols="12" lg="3" v-for="(item,i) in statistics" :key="i">
            <v-card outlined class="rounded">
              <v-card-text>
                <div class="d-flex align-center justify-space-between">
                  <div>
                    <h4 class="grey--text text--darken-4 mb-2"> {{ item.name }}</h4>
                    <span class="gifty-text font-weight-medium">
                       {{ CurrencyFormatting(item.total_amount) }} DZD
                    </span>
                  </div>
                  <div>
                    <v-avatar class="rounded-lg" :color="item.background_color">
                      <v-img contain :src="fileUrl + item.image"></v-img>
                    </v-avatar>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      show: false,
      isLoading: false,
      fileUrl: process.env.VUE_APP_FILE_URL,
      statistics: {},
    }
  },
  methods: {
    getEPaymentsStatistics() {
      this.isLoading = true
      HTTP.get('/v1/e-payments/statistics').then((res) => {
        this.statistics = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getEPaymentsStatistics()
  }
}
</script>

<style scoped>

</style>